<script>
	import '../app.css';
	import { QueryClient, QueryClientProvider } from '@sveltestack/svelte-query';
	import { Toaster } from 'svelte-french-toast';
	import { unauthorized } from '$lib/stores/auth';

	const queryClient = new QueryClient();

	// Clear cache when unauthorized status is detected (feel like I am being silly with this ...)
	$: if ($unauthorized === true) {
		queryClient.clear();
		unauthorized.set(false);
	}
</script>

<QueryClientProvider client={queryClient}>
	<slot />
	<Toaster />
</QueryClientProvider>
